'use strict';
(function () {

  class RepairComponent {
    constructor($scope, Repair, Finding, Auth, Image, Upload, Modal, toastr, usSpinnerService, $stateParams, $state, $window, $uibModal, Report, ActivityLog, SharedUrl,$sce) {
      this.Repair = Repair;
      this.Image = Image;
      this.Upload = Upload;
      this.toastr = toastr;
      this.Finding = Finding;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.$window = $window;
      this.$uibModal = $uibModal;
      this.usSpinnerService = usSpinnerService;
      this.isLoggedIn = Auth.isLoggedIn;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.Modal = Modal;
      this.repairWorkOrder = {};
      this.isDisabled = false;
      this.Report = Report;
      this.ActivityLog = ActivityLog;
      this.SharedUrl = SharedUrl;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.$sce = $sce;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.init();
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });

      this.bladePositionMap = {
        'A': 1,
        'B': 2,
        'C': 3
      };

      this.shellPositionArray = [1,2,3,4,5,6,7,8,9,10,11,12]
    }

    $onInit() {
    }

    moveRepair(repair) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/repair/move-repair-modal.html',
        controller: 'MoveRepairModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Move Repair',
            repair: repair,
            site: repair.turbine.site
          }
        }
      });
      modalInstance.result.then(result => {
        this.fetchRepairs();
      });
    }

    buildBladeComponents() {
      if (this.repair.finding) {
        let finding = this.repair.finding;
        let repair = _.omit(this.repair, 'finding');
        finding.repairs = [repair];
        this.bladeComponents = [{ findings: [finding], blade: this.repair.blade }];
      }
    }

    init() {
      this.loading = true;
      this.Repair.get({ id: 'get-repair', controller: this.$stateParams.id }).$promise
        .then(response => {
          if (response.data) {
            this.repair = response.data;
            this.buildBladeComponents();
            // get overview images array first images from all steps
            this._setOverviewImages();
            this.setDragAndDropImagesSection();
            console.log('repair : ', this.repair);
            this.bladeStr = '';
            if(this.repair.blade.make) {
              this.bladeStr+=this.repair.blade.make+' ';
            }
            if(this.repair.blade.model) {
              this.bladeStr+=this.repair.blade.model;
            }
            if(this.repair.blade.name) {
              this.bladeStr+=this.repair.blade.name;
            }

            this.turbineStr = '';
            if(this.repair.turbine.make) {
              this.turbineStr+=this.repair.turbine.make;
            }
            if(this.repair.turbine.model) {
              this.turbineStr+=this.repair.turbine.model;
            }
            if(this.repair.turbine.name) {
              this.turbineStr+=this.repair.turbine.name;
            }
            if (this.repair.turbine.site) {
              this.siteOverview = {
                reportNumber: Math.round(Date.now() / 1000),
                siteName: this.repair.turbine.site.name,
                siteLocation: `${this.repair.turbine.site.location.addressLine3}, ${this.repair.turbine.site.location.addressLine4}, ${this.repair.turbine.site.location.country}`,
                sitePosition: this.repair.turbine.sitePosition,
                turbineSerialNo: this.repair.turbine.serial,
                turbineBlades: [this.repair.blade],
                turbineMake: this.repair.turbine.make,
                turbineModel: this.repair.turbine.model,
                completionDate: new Date(this.repair.date || this.repair.createdAt),
                reportDate: new Date()
              };
            }
          }

          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err => {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
        });
    }

    _setOverviewImages() {
      this.overviewImages = [];
      this.totalMaterialsUsed = 0;
      try {
        if (this.repair.images && this.repair.images.length > 0) {
          this.overviewImages.push(this.repair.images[0]);
        }
        if (this.repair.closeUpImages && this.repair.closeUpImages.length > 0) {
          this.overviewImages.push(this.repair.closeUpImages[0]);
        }
        if (this.repair.defectProcedure && this.repair.defectProcedure.conditions) {
          this.repair.defectProcedure.conditions.forEach(condition => {
            if (condition.images.length > 0) {
              for(let i=0; i< condition.images.length; i++) {
                let image = condition.images[i];
                if(image.path) {
                  this.overviewImages.push(image);
                  break;
                }
              }
              // this.overviewImages.push(condition.images[0]);
            }
            if (condition.materialsUsed && condition.materialsUsed.length > 0) {
              this.totalMaterialsUsed += condition.materialsUsed.length;
            }
          });
        }

        for(let i = 1; i <= 12; i++) {
          for(let j = 1; j <= 4; j++) {
            if(this.repair.elleApplication && this.repair.elleApplication[`shellPosition${i}`] && this.repair.elleApplication[`shellPosition${i}`][`shellPositionImage${j}`]) {
              this.overviewImages.push({path: this.repair.elleApplication[`shellPosition${i}`][`shellPositionImage${j}`]});
            }
          }
        }

        console.log('overview images: ', this.overviewImages);
        // console.log('this.totalMaterialsUsed: ', this.totalMaterialsUsed);
      } catch (error) {
        console.error('set overview images failed: ', error);
      }
    }

    setDragAndDropImagesSection() {
      this.imageSection = {
        lists: {"IMAGE": [], "CLOSE_UP": []}
      };
      this.repair.images.forEach( image => {
        if(image.path) {
          this.imageSection.lists.IMAGE.push(image);
        }
      })
      this.repair.closeUpImages.forEach( image => {
        if(image.path) {
          this.imageSection.lists.CLOSE_UP.push(image);
        }
      })
      if (this.repair.defectProcedure && this.repair.defectProcedure.conditions) {
        this.repair.defectProcedure.conditions.forEach(condition => {
          let conditionName = condition.name;
          this.imageSection.lists[conditionName] = [];
          if (condition.images.length > 0) {
            condition.images.forEach(image => {
              if (image.path) {
                this.imageSection.lists[conditionName].push(image);
              }
            })
          }
        });
      }
      for(let i = 1; i <= 12; i++) {
        this.imageSection.lists[`shellPosition${i}`] = [];
        for(let j = 1; j <= 4; j++) {
          if(this.repair.elleApplication && this.repair.elleApplication[`shellPosition${i}`] && this.repair.elleApplication[`shellPosition${i}`][`shellPositionImage${j}`]) {
            this.imageSection.lists[`shellPosition${i}`].push({
              [`shellPositionImage${j}`]: this.repair.elleApplication[`shellPosition${i}`][`shellPositionImage${j}`],
            });
          } else {
            this.imageSection.lists[`shellPosition${i}`].push({
              [`shellPositionImage${j}`]: '',
            });
          }
        }
      }
      console.log('this.imageSection: ', this.imageSection);
    }

    onDrop(srcList, srcIndex, targetList, targetIndex) {
      console.log('srcList', srcList);
      console.log('targetList', targetList);
      console.log('srcIndex, targetIndex', srcIndex, targetIndex);

      let srcIndexString = srcIndex.toString();
      let targetIndexString = targetIndex.toString();
      let srcIndexSplit = srcIndexString.split("_");
      let targetIndexSplit = targetIndexString.split("_");
      let updatedTargetIndex = parseInt(targetIndexSplit[0]);
      let updatedSrcIndex = parseInt(srcIndexSplit[0]);

      if(targetIndexSplit[1] && targetIndexSplit[1] == 'Image') {
        targetList[updatedTargetIndex][`shellPositionImage${updatedTargetIndex+1}`] = srcList[updatedSrcIndex][`shellPositionImage${updatedSrcIndex+1}`] || srcList[srcIndex].path;
      } else {
        targetList.splice(targetIndex, 0, srcList[srcIndex] || { path: srcList[updatedSrcIndex][`shellPositionImage${updatedSrcIndex+1}`] });
      }

      if(srcIndexSplit[1] && srcIndexSplit[1] == 'Image') {
        srcList[updatedSrcIndex][`shellPositionImage${updatedSrcIndex+1}`] = '';
      } else {
        if (srcList == targetList && targetIndex <= srcIndex) srcIndex++;
        srcList.splice(srcIndex, 1);
      }

      // Copy the item from source to target.
      // targetList.splice(targetIndex, 0, srcList[srcIndex]);
      // // Remove the item from the source, possibly correcting the index first.
      // // We must do this immediately, otherwise ng-repeat complains about duplicates.
      // if (srcList == targetList && targetIndex <= srcIndex) srcIndex++;
      // srcList.splice(srcIndex, 1);
      
      console.log(this.imageSection);
      
      const repair = angular.copy(this.repair);
      repair.images = this.imageSection.lists.IMAGE;
      repair.closeUpImages = this.imageSection.lists.CLOSE_UP;
      if (repair.defectProcedure && repair.defectProcedure.conditions) {
        repair.defectProcedure.conditions.forEach(condition => {
          let conditionName = condition.name;
          condition.images = [];
          condition.images = this.imageSection.lists[conditionName];
        });
      }

      for(let i = 1; i <= 12; i++) {
        for(let j = 1; j <= 4; j++) {
          repair.elleApplication[`shellPosition${i}`][`shellPositionImage${j}`] = this.imageSection.lists[`shellPosition${i}`] && this.imageSection.lists[`shellPosition${i}`][j-1] && this.imageSection.lists[`shellPosition${i}`][j-1][`shellPositionImage${j}`];
        }
      }

      console.log('Repair after drag and drop', repair);

      this.Repair.updateOnDragDrop({ id: this.repair._id }, repair).$promise
        .then(response => {
          this.toastr.success('Image has been moved.');
          this.repair = _.mergeWith(this.repair,  _.omit(response.data, ['turbine', 'blade', 'findings']), (objValue, srcValue) => {
            if (_.isArray(objValue)) {
              return srcValue;
            }
          });
          console.log(this.repair);
          this.setDragAndDropImagesSection();
        })
        .catch(err => {
          console.log('drag and drop image err', err);
          if (err.data && err.data.meta && err.data.meta.error_type == 'VersionError') {
            this.toastr.error(err.data.meta.error_message);
          } else {
            this.toastr.error('Image was not moved. please try again later');
          }
        });

      // By returning true from dnd-drop we signalize we already inserted the item.
      return true;
    }

    getBladePosition(position) {
      return this.bladePositionMap[position] ? this.bladePositionMap[position] : position;
    }

    openFullImage(url) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-full-image/viewFullImageModal.html',
        controller: 'ViewFullImageModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          imageUrl: function () { return url }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    openManageRepairModal(repair) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/repair/manage-repair-modal.html',
        controller: 'ManageRepairModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Repair',
            repair: repair,
            boxW: 400,
            boxH: 400,
            lens: false
          }
        }
      });
      modalInstance.result.then(result => {
        this.init();
        //console.log('add repair result: ', result);
        // this.$scope.$broadcast('bladeComponent', { refresh: true });
      });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    print() {
      this.$window.print();
      const activityLogData = {
        site: this.repair.turbine.site._id,
        turbine: this.repair.turbine._id,
        blade: this.repair.blade._id,
        finding: this.repair.finding._id,
        repair: this.repair._id,
        user: this.currentUser._id,
        source: 'web',
        action: 'print',
        type: 'repair',
        sourceScreen: 'repair',
        eventString: `Repair work order <a href="/repair/${this.repair._id}">${this.repair.woId}</a> was printed in wind farm <a href="/site/${this.repair.turbine.site._id}">${this.repair.turbine.site.name}</a>, turbine <a href="/turbine/${this.repair.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.repair.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
      }

      this.ActivityLog.save(activityLogData).$promise
        .then(activityLog => {
          console.log(activityLog);
        })
        .catch(err => {
          console.log('Error inserting activityLog', err);
      });
    }

    linkFinding() {
      const findingData = {};
      this.isDisabled = true;
      findingData.blade = this.repair.blade._id;
      findingData.turbine = this.repair.turbine._id;
      findingData.bladePosition = this.repair.blade.position;
      findingData.type = 'INSPECTION';
      findingData.date = this.repair.createdAt;
      findingData.inspectionType = 'INTERNAL';
      findingData.bladeSide = this.repair.locationInBlade;
      // findingData.cavityInspectionType = 'LE Cavity';

      findingData.chordDistance = this.repair.chordDistance;
      findingData.defectDepthThickness = this.repair.defectDepth;
      findingData.defectLengthSpan = this.repair.defectSizeSpan;
      findingData.defectWidthChord = this.repair.defectSizeChord;
      findingData.distanceFromRoot = parseInt(this.repair.defectFrom);
      findingData.extentOfDamage = 'NO DAMAGE';
      findingData.woId = this.repair.woId;
      findingData.numberOfDefects = this.repair.numberOfDefects;
      findingData.turbineSerial = this.repair.turbine.serial;

      const findingImages = [];
      if (this.repair.images && this.repair.images.length > 0) {
        findingImages.push(this.repair.images[0].path);
      }
      findingData.images = findingImages;
      console.log('Finding Data: ', findingData);
      this.Finding.save(findingData).$promise
        .then(finding => {
          this.stopSpin('spinner-1');
          console.log(finding.data._id);
          this.Repair.linkFinding({ id: this.repair._id }, { finding: finding.data._id }).$promise
            .then(response => {
              this.isDisabled = false;
              console.log('finding added to repair', response);
              this.$state.reload();
            })
            .catch(err => {
              console.log('Err', err);
              this.isDisabled = false;
              this.toastr.error('Something went wrong while adding finding to repair.');
            });
        })
        .catch(err => {
          this.isDisabled = false;
          this.errors = {};
          this.stopSpin('spinner-1');
          this.toastr.error('Something went wrong while adding finding.');
        });
    }

    removeImage(index, imageType, condition) {
      this.Modal.confirm.delete(() => {
        const repair = angular.copy(this.repair);
        if (imageType === 'IMAGE') {
          repair.images.splice(index, 1);
        } else if (imageType === 'CLOSE_UP') {
          repair.closeUpImages.splice(index, 1);
        } else if (condition) {
          const conditionObj = _.find(repair.defectProcedure.conditions, { name: condition.name });
          conditionObj.images.splice(index, 1);
        }
        // console.log('new repair: ', repair);
        this.Repair.update({ id: this.repair._id }, repair).$promise
          .then(response => {
            this.toastr.success('Image has been removed from repair.');
            // console.log('repair update result: ', response.data);
            // this.repair = response.data;
            this.repair = _.mergeWith(this.repair,  _.omit(response.data, ['turbine', 'blade', 'findings']), (objValue, srcValue) => {
              if (_.isArray(objValue)) {
                return srcValue;
              }
            });
            this.setDragAndDropImagesSection();
          })
          .catch(err => {
            this.isUploading = false;
            console.log('update err', err);
            if (err.data && err.data.meta && err.data.meta.error_type == 'VersionError') {
              this.toastr.error(err.data.meta.error_message);
            } else {
              this.toastr.error('Image was not removed from repair. please try again later');
            }
          });
      })('image from repair');
    }

    removeShellPositionImage(shellPositionIndex, shellPositionImageIndex) {
      this.Modal.confirm.delete(() => {
        const repair = angular.copy(this.repair);
        repair.elleApplication[`shellPosition${shellPositionIndex}`][`shellPositionImage${shellPositionImageIndex}`] = '';
        this.Repair.update({ id: this.repair._id }, repair).$promise
          .then(response => {
            this.toastr.success('Image has been removed from repair.');
            this.repair = _.mergeWith(this.repair,  _.omit(response.data, ['turbine', 'blade', 'findings']), (objValue, srcValue) => {
              if (_.isArray(objValue)) {
                return srcValue;
              }
            });
            this.setDragAndDropImagesSection();
          })
          .catch(err => {
            this.isUploading = false;
            console.log('update err', err);
            if (err.data && err.data.meta && err.data.meta.error_type == 'VersionError') {
              this.toastr.error(err.data.meta.error_message);
            } else {
              this.toastr.error('Image was not removed from repair. please try again later');
            }
          });
      })('image from repair');
    }

    uploadShellPositionImage(file, shellPositionIndex, shellPositionImageIndex) {
      this.uploadImageType = `shellPosition_${shellPositionIndex}_${shellPositionImageIndex}`;
      var filename = file.name;
      var type = file.type;
      var query = {
        filename: filename,
        type: type
      };
      this.isUploading = true;
      this.Image.signing({}, query).$promise
        .then(result => {
          this.Upload.upload({
            url: result.url, //s3Url
            transformRequest: function (data, headersGetter) {
              var headers = headersGetter();
              delete headers.Authorization;
              return data;
            },
            fields: result.fields, //credentials
            method: 'POST',
            file: file
          }).progress((evt) => {
            this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
          }).success((data, status, headers, config) => {
            this.isUploading = false;
            // file is uploaded successfully
            const repair = angular.copy(this.repair);
            const imagePath = result.url + '/' + result.fields.key;
            repair.elleApplication[`shellPosition${shellPositionIndex}`][`shellPositionImage${shellPositionImageIndex}`] = imagePath;
            this.Repair.update({ id: this.repair._id }, repair).$promise
              .then((response) => {
                this.toastr.success('Image added to repair');
                console.log('updatedRepair', response.data);
                // this.repair = response.data;
                this.repair = _.mergeWith(this.repair,  _.omit(response.data, ['turbine', 'blade', 'findings']), (objValue, srcValue) => {
                  if (_.isArray(objValue)) {
                    return srcValue;
                  }
                });
                this.setDragAndDropImagesSection();
              })
              .catch(err => {
                this.isUploading = false;
                console.log('update err', err);
                if (err.data && err.data.meta && err.data.meta.error_type == 'VersionError') {
                  this.toastr.error(err.data.meta.error_message);
                } else {
                  this.toastr.error('Image was not added to repair. please try again later');
                }
              });
          }).error((err) => {
            console.log('err', err);
            this.toastr.error('Image was not added to repair. please try again later');
          });
        });
    }

    uploadImage(file, imageType, condition) {
      this.uploadImageType = imageType;
      var filename = file.name;
      var type = file.type;
      var query = {
        filename: filename,
        type: type
      };
      this.isUploading = true;
      this.Image.signing({}, query).$promise
        .then(result => {
          this.Upload.upload({
            url: result.url, //s3Url
            transformRequest: function (data, headersGetter) {
              var headers = headersGetter();
              delete headers.Authorization;
              return data;
            },
            fields: result.fields, //credentials
            method: 'POST',
            file: file
          }).progress((evt) => {
            this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total));
          }).success((data, status, headers, config) => {
            this.isUploading = false;
            // file is uploaded successfully
            // console.log('file ' + config.file.name + 'is uploaded successfully. Response: ' + data);
            const repair = angular.copy(this.repair);
            const imagePath = result.url + '/' + result.fields.key;
            if (imageType === 'IMAGE') {
              repair.images.push({ path: imagePath, isPrimary: false });
            } else if (imageType === 'CLOSE_UP') {
              repair.closeUpImages.push({ path: imagePath, isPrimary: false });
            } else if (condition) {
              const conditionObj = _.find(repair.defectProcedure.conditions, { name: condition.name });
              conditionObj.images.push({ path: imagePath });
            }
            this.Repair.update({ id: this.repair._id }, repair).$promise
              .then((response) => {
                this.toastr.success('Image added to repair');
                console.log('updatedRepair', response.data);
                // this.repair = response.data;
                this.repair = _.mergeWith(this.repair,  _.omit(response.data, ['turbine', 'blade', 'findings']), (objValue, srcValue) => {
                  if (_.isArray(objValue)) {
                    return srcValue;
                  }
                });
                this.setDragAndDropImagesSection();
              })
              .catch(err => {
                this.isUploading = false;
                console.log('update err', err);
                if (err.data && err.data.meta && err.data.meta.error_type == 'VersionError') {
                  this.toastr.error(err.data.meta.error_message);
                } else {
                  this.toastr.error('Image was not added to repair. please try again later');
                }
              });
          }).error((err) => {
            console.log('err', err);
            this.toastr.error('Image was not added to repair. please try again later');
          });
        });
    }

    createReport() {
      console.log('createReport called');
      this.Modal.confirm.confirmCreateReport(success => {
        console.log('yes');
        const addReportData = {
          _id: this.repair.turbine.site._id,
          site: this.repair.turbine.site,
          turbines: [{'_id': this.repair.turbine._id}],
          blades: [{'_id': this.repair.blade._id}],
          repairs: [this.repair._id],
          type: 'repair',
          entityType: 'repairs',
          entityId: this.repair._id,
          reportNumber: Date.now(),
          createdBy: this.currentUser._id
        }
        console.log('addReportData:', addReportData);
        this.Report.addReport(addReportData).$promise
        .then(report => {
          this.toastr.success('Report request submitted.');
          const activityLogData = {
            site: this.repair.turbine.site._id,
            turbine: this.repair.turbine._id,
            blade: this.repair.blade._id,
            finding: this.repair.finding && this.repair.finding._id,
            repair: this.repair._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'create report',
            type: 'repair',
            sourceScreen: 'repair',
            eventString: `Repair Report was created for wind farm <a href="/site/${this.repair.turbine.site._id}">${this.repair.turbine.site.name}</a>, turbine <a href="/turbine/${this.repair.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.repair.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
          }
    
          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });
          // this.$state.reload();
        })
        .catch(err => {
          console.log(err);
          this.error = err;
          this.toastr.error('Something went wrong. please try later or contact administrator.');
        });
      },'Create Report', `<p>A report will be emailed to ${this.currentUser.email}. Proceed?</p>`);
    }

    shareUrl() {
      const shareUrlData = {
        url: `repair/${this.repair._id}`,
        level: 'repair',
        organization: this.currentUser.organization._id,
      }
      var url = window.location.href;
      var urlArray = url.split("/");
      var baseUrl = urlArray[0] + "//" + urlArray[2];
      this.SharedUrl.save(shareUrlData).$promise
        .then(sharedUrlResponse => {
          this.urlShared = `${baseUrl}/shared/${sharedUrlResponse.data._id}`;
          this.htmlPopover = this.$sce.trustAsHtml(`${this.urlShared} <i>Users without an account will see a read-only version of this page.</i>`);
          this.showShareUrlPopup = true;
          var dummyElement = document.createElement("textarea");
          document.body.appendChild(dummyElement);
          dummyElement.value = this.urlShared;
          dummyElement.select();
          document.execCommand("copy");
          document.body.removeChild(dummyElement);
          this.toastr.success('Link copied successfully.');
        })
        .catch(err => {
          console.log('Error inserting sharedUrl', err);
      });
    }
  }

  angular.module('windmanagerApp')
    .component('repair', {
      templateUrl: 'app/repair/repair.html',
      controller: RepairComponent,
      controllerAs: 'rc'
    });

})();
